<script setup lang="ts">
import { vIntersectionObserver } from "@vueuse/components";
import VText from "@magnit/core/src/components/VText/VText.vue";
import VIcon from "@magnit/icons/src/components/VIcon/VIcon.vue";
import VHidden from "@magnit/core/src/components/VHidden/VHidden.vue";
import VModal from "@magnit/core/src/components/VModal/VModal.vue";
import VTooltip from "@magnit/core/src/components/VTooltip/VTooltip.vue";
import CosmeticIcon from "@magnit/icons/src/assets/icons/logo-cosmetic-40-color-square.svg";
import ArrowIcon from "@magnit/icons/src/assets/icons/arrow-right-corner-24.svg";
import StoreSelectPrompt from "@magnit/unit-shop-select/src/components/ShopSelectPrompt.vue";
import VLoader from "@magnit/core/src/components/VLoader/VLoader.vue";
import VShopSelect from "@magnit/unit-shop-select/src/VShopSelect.vue";
import { scrollLock } from "@magnit/core/src/helpers/scrollLock";
import type { IShopSelectShop } from "@magnit/unit-shop-select/src/shop-select.types";
import type { IStoreSearchStore } from "~/typings/api/storeSearch";

const storesStore = useStoresStore();
const {
  initialized,
  currentStoreAddress,
  requestShopId,
  isDiffShopCodeQuery,
  storePrompted,
  currentStoreCity,
} = storeToRefs(storesStore);
const shopSelectOpen = ref(false);
const modalOpen = ref(true);
const { send } = useAnalytics();

const showStorePrompt = computed(
  () =>
    !storePrompted.value && !isDiffShopCodeQuery.value && !shopSelectOpen.value,
);
const sendAnalytic = (name: string) => {
  send(name, {
    selected_store: requestShopId.value || null,
    selected_city: currentStoreCity.value || null,
  });
};
const handleFigureClick = () => {
  sendAnalytic("Header:CityStore:Click");
  if (shopSelectOpen.value === true) return;
  scrollLock.enable();
  shopSelectOpen.value = true;
};
const onVisibility = ([{ isIntersecting }]: IntersectionObserverEntry[]) => {
  if (!isIntersecting) return null;
  sendAnalytic("Header:CityStore:View");
};
const handleShopSelectClose = () => {
  scrollLock.disable();
  shopSelectOpen.value = false;
};
const onStorePromptApprove = () => {
  storesStore.setStorePrompt();
};
const onStorePromptReject = () => {
  handleFigureClick();
};

const {
  mapBounds,
  selectedShop,
  shopSearchQuery,
  mapLoading,
  listLoading,
  storesListRef,
  mapCenter,
  shopsFilterOptions,

  handleMapBoundsChange,
  handleSearchQueryChange,
} = useShopSelect();

const handleShopSelectSubmit = async (payload: { shop: IShopSelectShop; }) => {
  mapLoading.value = true;
  try {
    await storesStore.submitSelectedStore(payload.shop as IStoreSearchStore);
    handleShopSelectClose();
  } catch (err) {
    logError("Ошибка при попытке получения идентификатора магазина", { err });
  } finally {
    mapLoading.value = false;
  }
};
</script>

<template>
  <section class="app-location">
    <div role="button" class="app-location__inner" @click="handleFigureClick">
      <div v-if="!initialized" class="app-location__inner--loading">
        <VLoader size="s" />
      </div>
      <template v-else>
        <CosmeticIcon class="app-location__icon" />
        <div
          v-intersection-observer="onVisibility"
          class="app-location__info"
          :title="currentStoreAddress"
        >
          <VText
            class="app-location__info--address"
            :font="{
              xs: 'body-small-accent-low',
              m: 'caption-large-accent-low',
            }"
          >
            {{ currentStoreAddress }}
          </VText>
        </div>
        <VHidden :hide="{ s: false, m: true }">
          <VIcon class="app-location__arrow" color="secondary">
            <ArrowIcon />
          </VIcon>
        </VHidden>
      </template>
    </div>
    <Transition name="scale">
      <VShopSelect
        v-if="shopSelectOpen"
        v-model:modalOpen="modalOpen"
        class="app-shop-select"
        :yandex-map-key="YANDEX_MAP_API_KEY"
        :map-loading="mapLoading"
        :shops-list-loading="listLoading"
        :bounding-box="mapBounds"
        :center="mapCenter"
        :shops="storesListRef"
        :shops-search-string="shopSearchQuery"
        :shops-filter-options="shopsFilterOptions"
        :selected-shop="selectedShop"
        @submit:bounding-box="handleMapBoundsChange"
        @submit:shops-search="handleSearchQueryChange"
        @submit:shop-select="handleShopSelectSubmit"
        @close:shop-select="handleShopSelectClose"
      />
    </Transition>
    <VHidden class="app-location-store-wrapper" :hide="{ xs: true, m: false }">
      <VTooltip
        v-if="isDiffShopCodeQuery"
        show-on-create
        interactive
        :offset="[0, -8]"
        :max-width="431"
        :show-arrow="false"
        :hide-on-click="false"
        :closable="false"
        trigger="manual"
        placement="bottom-end"
      >
        <template #content>
          <AppLocationShop />
        </template>
      </VTooltip>
    </VHidden>
    <VModal
      id="store"
      :closable="false"
      wrapper-class="store-bottom-sheet"
      active
      :open="isDiffShopCodeQuery"
      type="bottom-sheet"
    >
      <AppLocationShop />
    </VModal>
    <StoreSelectPrompt
      :address="currentStoreAddress"
      :show="showStorePrompt"
      @approve="onStorePromptApprove"
      @reject="onStorePromptReject"
    />
  </section>
</template>

<style lang="postcss" scoped>
@keyframes scale-up {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

.scale-enter-active {
  animation: scale-up 0.3s;
}

.scale-leave-active {
  animation: scale-up 0.3s reverse;
}

.app-location {
  display: flex;
  flex-direction: column;

  &__inner {
    display: flex;
    gap: var(--pl-unit-x2);
    padding: var(--pl-unit-x2) 0;
    align-items: center;
    cursor: pointer;
    width: 100%;
    position: relative;

    &--loading {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
    }

    @media (--pl-viewport-from-m) {
      width: 217px;
      border: 1px solid var(--pl-border-secondary-disabled);
      border-radius: var(--pl-button-radius-l);
      padding: 6px var(--pl-unit-x3);
      background-color: var(--pl-button-background-tertiary-default);

      &:hover {
        border-color: var(--pl-button-border-hover);
        background-color: var(--pl-button-background-tertiary-hover);
      }

      &:active {
        border-color: var(--pl-button-border-pressed);
        background-color: var(--pl-button-background-tertiary-pressed);
      }
    }
  }

  &__icon {
    width: 32px;
    height: 32px;
  }

  &__arrow {
    display: flex;
  }

  &__info {
    flex: 1;
    overflow: hidden;

    &--address {
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  :deep(.tippy-content) {
    padding: var(--pl-unit-x4);
  }
}

.app-location-store-wrapper {
  height: 0;
  width: 0;
  align-self: flex-end;
}

:global(.store-bottom-sheet .pl-modal-content) {
  padding: var(--pl-unit-x8) var(--pl-unit-x5);
}

:global(.store-bottom-sheet .app-location-store__inner--actions) {
  flex-direction: column;
}

:global(.store-bottom-sheet) {
  @media (--pl-viewport-from-m) {
    display: none !important;
  }
}
</style>
