<script lang="ts" setup>
import { cloneDeep } from "lodash-es";
import {
  CLIENT_LINKS,
  COMPANY_LINKS,
  MANAGEMENT_LINKS,
} from "@magnit/unit-navigation/src/VNavigation.vars";
import type { ISubMenuLinks } from "@magnit/unit-navigation/src/VNavigation.types";
import VHeader from "@magnit/unit-header/src/VHeader.vue";
import { scrollLock } from "@magnit/core/src/helpers/scrollLock";
import { provideKeys } from "@magnit/unit-catalog/src/unit-catalog.vars";
import type { ICategory } from "~/typings/categories";

const router = useRouter();
const route = useRoute();
const categoriesStore = useCategoriesStore();
const modalsStore = useModalsStore();
const authStore = useAuthStore();
const { send } = useAnalytics();

const isAdultApproved = inject<Ref<boolean>>(provideKeys.approved, ref(false));
const isAgeConfirmationActive = inject<Ref<boolean>>(
  LocalProvidersKeys.ageConfirmationActive,
  ref(false),
);

const openMenu = ref(false);
const openSearchSuggest = ref(false);
const searchValue = ref((route.query.term as string) || "");

const clientlinks = cloneDeep(CLIENT_LINKS);
const companylinks = cloneDeep(COMPANY_LINKS);
clientlinks.links[0].url =
  "https://magnit.ru/mplus/?utm_source=magnit.ru&utm_campaign=mplus&utm_medium=header";
companylinks.links.splice(2, 0, {
  label: "Условия использования файлов cookies",
  url: Routes.CookiePolicy,
  onClick: (e: Event) => {
    e.preventDefault();
    router.push(Routes.CookiePolicy);
  },
});
const submenuLinks: ISubMenuLinks = [
  clientlinks,
  MANAGEMENT_LINKS,
  companylinks,
];

const onClickAuth = async () => {
  send("Toolbar:Auth:Click");
  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  authStore.state.authorized || (await authStore.checkLogin())
    ? router.push(Routes.Profile)
    : modalsStore.open(MODALS.Login);
};
const onClickItem = (category: ICategory) => {
  router.push(category.url);
  openMenu.value = false;
};

const onSearchSubmit = (searchValue: string) => {
  router.push(encodeURI(`${Routes.Search}?term=${searchValue}`));
};

const onSearchClear = () => {};

const checkSearchValue = () => {};
onMounted(() => {
  send("Toolbar:Auth:View");
});
watch([openMenu, openSearchSuggest], (next) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  next[0] || next[1] ? scrollLock.enable() : scrollLock.disable();
});

watch(
  () => openSearchSuggest.value,
  (next) => {
    if (!next) {
      if (isAgeConfirmationActive.value) {
        openSearchSuggest.value = true;
      }
    }
  },
);
watch(
  () => isAdultApproved.value,
  (next) => {
    if (next) {
      nextTick(() => (openSearchSuggest.value = false));
    }
  },
);
</script>

<template>
  <LayoutDefaultButterflies />
  <VHeader
    v-model:open-catalog="openMenu"
    v-model:open-search-suggest="openSearchSuggest"
    v-model:search-value="searchValue"
    class="js-header header-cosmetic"
    project="cosmetic"
    :submenu-links="submenuLinks"
    @auth:click="onClickAuth"
    @search:submit="onSearchSubmit"
    @search:focus="checkSearchValue"
    @search:clear="onSearchClear"
  >
    <template #top>
      <ClientOnly>
        <AppInstallBanner />
      </ClientOnly>
    </template>
    <template #catalog>
      <AppCatalogNavigation
        :items="categoriesStore.structure"
        :open="openMenu"
        root
        @item:click="onClickItem"
      />
    </template>
    <template #location>
      <ClientOnly>
        <AppLocation />
      </ClientOnly>
    </template>
    <template #search>
      <AppSearchSuggest
        v-model:open-suggest="openSearchSuggest"
        v-model:search-value="searchValue"
      />
    </template>
  </VHeader>
</template>

<style lang="postcss" scoped>
:global(.pl-header .pl-header-catalog-menu__content) {
  --pl-header-catalog-menu-content: 0;

  width: 100%;

  @media (--pl-viewport-from-ml) {
    --pl-header-catalog-menu-content: calc(var(--pl-wrapper-indent) - 16px);

    width: initial;
  }
}

/* некоторые стили стоят чтобы не разламывало шапку, пока полные стили из дизайн системы подгружаются */

/* TODO: по-хорошему надо проресёрчить порядок загрузки и почему эти стили включает в очередь загрузки позднее чем хотелось-бы? */
:global(.pl-header .pl-navigation__container) {
  min-height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

:global(.pl-header .pl-navigation__links) {
  display: flex;
  align-items: center;
}

:global(.pl-header .pl-navigation-link__container) {
  display: flex;
  align-items: center;
}

:global(.header-magnit.pl-header) {
  background-color: var(--pl-global-background-primary);
}

:global(.body:not(.body_scrolled) .pl-header.pl-header_scrolled),
:global(.body:not(.body_scrolled) .pl-header-bmenu),
:global(.body:not(.body_scrolled) .pl-header__content-wrapper) {
  background-color: transparent;
}
</style>
